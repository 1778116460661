@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.markdown p {
  @apply text-sm text-gray-500;
}
.markdown a {
  @apply underline;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply ring-0;
}

.content p {
  @apply my-2 leading-normal text-gray-900;
}

.content h1 {
  @apply my-4 text-3xl font-bold;
}

.content h2 {
  @apply my-4 text-2xl font-bold;
}

.content h3 {
  @apply my-4 text-xl font-bold;
}

.content h4 {
  @apply my-4 text-lg font-bold;
}

.content h5 {
  @apply my-4 text-base font-bold;
}

.content h6 {
  @apply my-4 text-sm font-bold;
}

.content ul {
  @apply my-4 list-disc ml-4;
}

.content ol {
  @apply my-4 list-decimal ml-4;
}

.content li {
  @apply my-2 list-item
}

.content a {
  @apply text-blue-500 underline;
}

.content a:hover {
  @apply text-blue-700;
}

.content blockquote {
  @apply my-4 border-l-4 border-gray-300 pl-4 italic;
}

.content hr {
  @apply my-4 border-gray-300;
}
